import React from "react";
import { withTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import { CustomInput } from '../../components';
import { toast } from 'react-toastify';
import config from '../../config';

const styles = {
  button: {
    backgroundColor: "rgb(108, 108, 229)",
    borderRadius: "9px",
    padding: 0,
    height: "52px",
    lineHeight: "50px",
    fontWeight: 600,
    textAlign: "center",
    color: "#ffffff",
    fontSize: "17px",
    marginTop: "9px",
    marginBottom: "40px",
    boxSizing: "border-box",
    fontFamily: 'Avenir Heavy',
    textTransform: "none",
    width: "412px"
  }
};

class BookDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      company: "",
      message: "",
      isSubmitted: false
    };
  }

  handleStateChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  validate = (isSubmitted) => {
    const { t } = this.props;
    const validObj = {
      isValid: true,
      message: ""
    };
    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      company: validObj,
      isValid
    };
    if (isSubmitted) {
      const { name, email, company } = this.state;
      if (!name) {
        retData.name = {
          isValid: false,
          message: t("Name is required")
        };
        isValid = false;
      }
      if (!email) {
        retData.email = {
          isValid: false,
          message: t("Email is required")
        };
        isValid = false;
      } else if (email && !this.validateEmail(email)) {
        retData.email = {
          isValid: false,
          message: t("Enter valid email")
        };
        isValid = false;
      }
      if (!company) {
        retData.company = {
          isValid: false,
          message: t("Company is required")
        };
        isValid = false;
      }
    }
    retData.isValid = isValid;
    return retData;
  };

  onClickSubmit = () => {
    this.setState({
      isSubmitted: true
    });
    const errorData = this.validate(true);
    if (errorData.isValid) {
      const { name, email, company, message } = this.state;
      let sendData = {
        "email": email,
        "name": name,
        "company": company,
        "message": message
      }
      let headers = {
        'Content-Type': 'application/json',
      }
      this.setState({
        isLoading: true
      });
      fetch(`${config.apiUrl}/api/user/bookdemo`, { method: 'POST', headers, body: JSON.stringify(sendData) }).then(response => response.json())
        .then(data => {
          if (data.status) {
            toast('Message sent.', { containerId: 'TOP_RIGHT', autoClose: 5000, type: toast.TYPE.SUCCESS });
            this.setState({
              isLoading: false,
              name: "",
              email: "",
              company: "",
              message: "",
              isSubmitted: false
            });
          } else {
            toast(data.message, { containerId: 'TOP_RIGHT', autoClose: 5000, type: toast.TYPE.ERROR });
            this.setState({
              isLoading: false
            });
          }
        }, error => {
          toast('There is some error.', { containerId: 'TOP_RIGHT', autoClose: 5000, type: toast.TYPE.ERROR });
          this.setState({
            isLoading: false
          });
        });
    }
  };

  render() {
    const { t } = this.props;
    const { name, email, company, message, isSubmitted, isLoading } = this.state;
    const errorData = this.validate(isSubmitted);
    return (
      <>
        <div className="book-demo-block">
          <div className="book-demo-left">
            <h4>{t("Let´s have a chat")}</h4>
            <p>{t("We will take you through the platform and answer all of your questions. Submit the form and we will contact you shortly.")}</p>
            <ul>
              <li><i className="fa fa-check"></i> {t("Learn more about consumer testing through Tryio")}</li>
              <li><i className="fa fa-check"></i> {t("Get an overview over pricing and cost-efficiency")}</li>
              <li><i className="fa fa-check"></i> {t("Find the suitable use-case for your business")}</li>
            </ul>
            <p>{t("Regarding technical issues or general questions,")} <a href="mailto:tor-andre@tryio.no">{t("contact us here.")}</a></p>
          </div>
          <div className="book-demo-right">
            <CustomInput placeholder={t("Name*")} name="name" value={name} onChange={this.handleStateChange} isValid={errorData.name.isValid} message={errorData.name.message} />
            <CustomInput placeholder={t("E-mail*")} name="email" value={email} onChange={this.handleStateChange} isValid={errorData.email.isValid} message={errorData.email.message} />
            <CustomInput placeholder={t("Company*")} name="company" value={company} onChange={this.handleStateChange} isValid={errorData.company.isValid} message={errorData.company.message} />
            <textarea value={message} name="message" onChange={this.handleStateChange} placeholder={t("Your message (optional)")} className="text-area"></textarea>
            <Button onClick={this.onClickSubmit} className="bookdemo-button" style={styles.button} disabled={isLoading}>
              {
                isLoading &&
                <p className="loading"></p>
              }
              {
                !isLoading &&
                t("Book Demo")
              }
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const TranslatedDemo = withTranslation("translations")(BookDemo);
export { TranslatedDemo as BookDemo };