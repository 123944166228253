import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home, Tester, Blog, Registration, BookDemo } from "./screens";
import "./scss/app.scss";
import ReactGA from 'react-ga';
import { HeaderNavigation, Footer } from './components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.props.history.listen((location, action) => {
      window.scrollTo(0,0);
  });
  }
  render() {
    return (
      <>
        <HeaderNavigation {...this.props} />
        <Route path="/" exact component={Home} />
        <Route path="/tester" exact component={Tester} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/demo" exact component={BookDemo} />
        <Route path="/registration" component={Registration} />
        <Footer />
      </>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    ReactGA.initialize("UA-187791512-2");
  }
  render() {
    return (
      <Router>
        <div className="App">
          <div className="app-container">
            <Switch>
              <Route path="/" component={Layout} />
            </Switch>
            <ToastContainer enableMultiContainer containerId={'TOP_RIGHT'} position={toast.POSITION.TOP_RIGHT} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
