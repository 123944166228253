import React from "react";
import { doRequest } from "../../api/fetchRequest";
import { withTranslation } from "react-i18next";
import { CustomInput } from '../../components';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';

const styles = {
  button: {
    backgroundColor: "rgb(108, 108, 229)",
    borderRadius: "9px",
    padding: 0,
    height: "52px",
    lineHeight: "50px",
    fontWeight: 600,
    textAlign: "center",
    color: "#ffffff",
    fontSize: "17px",
    marginTop: "9px",
    marginBottom: "40px",
    boxSizing: "border-box",
    fontFamily: 'Avenir Heavy',
    textTransform: "none",
    width: "412px"
  }
};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      company: "",
      confirmPassword: "",
      isSubmitted: false,
    };
  }

  validate = (isSubmitted) => {
    const { t } = this.props;
    const validObj = {
      isValid: true,
      message: ""
    };
    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      password: validObj,
      company: validObj,
      confirmPassword: validObj,
      isValid
    };
    if (isSubmitted) {
      const { name, email, password, company, confirmPassword } = this.state;
      if (!name) {
        retData.name = {
          isValid: false,
          message: t("Name is required")
        };
        isValid = false;
      }
      if (!email) {
        retData.email = {
          isValid: false,
          message: t("Email is required")
        };
        isValid = false;
      } else if (email && !this.validateEmail(email)) {
        retData.email = {
          isValid: false,
          message: t("Enter valid email")
        };
        isValid = false;
      }
      if (!password) {
        retData.password = {
          isValid: false,
          message: t("Password is required")
        };
        isValid = false;
      }
      if (!confirmPassword) {
        retData.confirmPassword = {
          isValid: false,
          message: t("Confirm password is required")
        };
        isValid = false;
      }
      if (password && confirmPassword && password !== confirmPassword) {
        retData.confirmPassword = {
          isValid: false,
          message: t("Passwords are not matching")
        };
        isValid = false;
      }
      if (!company) {
        retData.company = {
          isValid: false,
          message: t("Company is required")
        };
        isValid = false;
      }
    }
    retData.isValid = isValid;
    return retData;
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  getRegistration = async () => {
    this.setState({
      isSubmitted: true
    });
    const errorData = this.validate(true);
    if (errorData.isValid) {
      const response = await doRequest(
        "https://europe-west2-tryio-6a1d3.cloudfunctions.net/api/signup",
        {
          email: this.state.email,
          password: this.state.password,
          name: this.state.name,
          organisationName: this.state.home,
        }
      );
      response !== undefined
        ? alert("Registration Success")
        : alert("Someting went wrong");
    }
  };

  handleStateChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { t } = this.props;
    const { name, email, company, password, confirmPassword, isSubmitted } = this.state;
    const errorData = this.validate(isSubmitted);
    return (
      <div>
        <div className="registration-container">
          <div className="registration-form">
            <h1> {t("Sign up as a business")} </h1>
            <p className="registration-form__footerText">
              {t("Want to become a tester?")} <a href="/#" className="form-footerText__link">{t("Click here.")}</a>
            </p>
            <CustomInput placeholder={t("Name*")} name="name" value={name} onChange={this.handleStateChange} isValid={errorData.name.isValid} message={errorData.name.message} icon={<PersonIcon />} />

            <CustomInput placeholder={t("E-mail*")} name="email" value={email} onChange={this.handleStateChange} isValid={errorData.email.isValid} message={errorData.email.message} icon={<EmailIcon />} />

            <CustomInput placeholder={t("Company name*")} name="company" value={company} onChange={this.handleStateChange} isValid={errorData.company.isValid} message={errorData.company.message} icon={<BusinessIcon />} />

            <CustomInput type="password" placeholder={t("Password*")} name="password" value={password} onChange={this.handleStateChange} isValid={errorData.password.isValid} message={errorData.password.message} icon={<LockIcon />} />

            <CustomInput type="password" placeholder={t("Confirm password*")} name="confirmPassword" value={confirmPassword} onChange={this.handleStateChange} isValid={errorData.confirmPassword.isValid} message={errorData.confirmPassword.message} icon={<LockIcon />} />
            <Button onClick={this.getRegistration} className="signup-button" style={styles.button}>{t("Sign up")}</Button>
            <p className="registration-form__footerText">
            {t("By registered I accept tryios")} <a href="https://appbackend.tryio.no/privacy-policies" target="_banck" className="form-footerText__link">{t("Privacy Policy")}</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const TranslatedRegistration = withTranslation("translations")(Registration);
export { TranslatedRegistration as Registration };