import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const Footer = ({ t }) => {
  return (
    <div className="footer-container">
      <div className="footer-wrapper">
        <ul className="link-block">
          <li>
            <Link to="/">
              {t("Home")}
            </Link>
          </li>
          <li>
            <a href="mailto:tor-andre@tryio.no">
              {t("Contact us")}
            </a>
          </li>
          <li>
            <a href="https://appbackend.tryio.no/privacy-policies" target="_blank">
              {t("Privacy policy")}
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/document/d/1JJhvSesYGI1gO6hmzrJdbK1S7AuJiFTo/edit?usp=sharing&ouid=116677954836103390589&rtpof=true&sd=true" target="_blank">
              {t("Terms of sales")}
            </a>
          </li>
        </ul>
        <div className="created-at-block">
          <p>&copy; {t("Tryio AS 2020")}</p>
        </div>
      </div>
    </div>
  );
};

const translatedFooter = withTranslation("translations")(Footer);
export { translatedFooter as Footer };
