import React from "react";

const HomeSection = ({
  height,
  fontSize,
  fontFamily,
  fontWeight,
  width,
  ...props
}) => {
  return (
    <div className="newTestRequest__section">
      <div className="newTestRequest-section__img"><div className="newTestRequest-section__img__inner"> {props.imgNumber}</div></div>
      <div className="newTestRequest__texts">
        <h4> {props.h4Text} </h4>
        <h5
          style={{
            fontWeight: fontWeight,
            fontFamily: fontFamily,
          }}
        >
          {" "}
          {props.h5Text}{" "}
        </h5>
      </div>
    </div>
  );
};

export default HomeSection;
